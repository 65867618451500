import { Button, Carousel } from "flowbite-react";
import { useState } from "react";
import Header from "../components/header";
import LandDrawer from "../components/landDrawer";
import LandingImage from '../res/landing.png';
import One from '../res/one.png';
import Two from '../res/two.png';
import Three from '../res/three.png';
import Four from '../res/four.png';
import KeyboardIcon from "../icons/keyboardIcon";
import MoreIcon from "../icons/moreIcon";
import MockUp from "../res/mockup.png";
import PlayStore from "../res/play.png";
import Phone from "../res/mobile.png";
import GenerateIcon from "../icons/generateIcon";
import Ratings from "../components/rating";
import Footers from "../components/footer";
import { useNavigate } from "react-router-dom";
import { playUrl } from "../constants";

const Landing = () => {

    const storedTheme = sessionStorage.getItem('darkMode');
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);
    const navigate = useNavigate();
    const handleMenuClick = () => {
        setIsOpen(true);
    };

    const reviews = [
        { from: 'Emily Chen', university: 'Stanford University', review: "As a Computer Science major at Stanford, I've tried numerous tools to streamline my learning process. Ai Text To Course Generator stands out for its remarkable ability to convert dense text into structured courses. The AI teacher chat is incredibly helpful, clarifying concepts in real-time. The short notes feature is a game-changer, aiding quick revision before exams. Plus, the audio courses are perfect for multitasking. Highly recommended for students seeking efficient and effective learning solutions.", photo: 'https://identity.stanford.edu/wp-content/uploads/sites/3/2020/07/SU_SealColor_web3.png' },
        { from: 'John Smith', university: 'Harvard Business School', review: "Coming from a business background, I value tools that enhance productivity and learning. Ai Text To Course Generator exceeded my expectations. It's like having a personal tutor available 24/7. The AI teacher chat provides instant assistance, ensuring I grasp complex concepts effortlessly. The short notes are concise yet comprehensive, ideal for busy professionals. And the audio courses? A lifesaver during my commute. Whether you're a student or a working professional, this tool is a must-have for accelerated learning.", photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Harvard_Crimson_logo.svg/1737px-Harvard_Crimson_logo.svg.png' },
        { from: 'Maria Rodriguez', university: 'MIT', review: "Pursuing a degree in Engineering at MIT demands efficient learning strategies. Ai Text To Course Generator has become my go-to tool for mastering intricate subjects. The AI teacher chat feature is incredibly intuitive, offering personalized explanations tailored to my learning pace. The short notes are succinct yet informative, aiding quick revision before exams. And the audio courses? Perfect for reinforcing concepts while on the go. If you're serious about mastering challenging topics, this tool is indispensable.", photo: 'https://brand.mit.edu/sites/default/files/styles/image_text_2x/public/2023-08/MIT-logo-red-textandimage.png' },
        { from: 'Sarah Johnson', university: 'Yale Law School', review: "Law school is known for its extensive reading lists and complex concepts. Ai Text To Course Generator has been a lifesaver throughout my academic journey at Yale. The AI teacher chat feature provides invaluable clarification on intricate legal principles, helping me grasp nuances that textbooks sometimes overlook. The short notes are concise yet comprehensive, serving as effective study aids before exams. And the audio courses? A welcome alternative to traditional study methods, allowing me to reinforce key concepts while on the move. If you're navigating the challenging terrain of law school, this tool is a game-changer.", photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/07/Yale_University_Shield_1.svg/1200px-Yale_University_Shield_1.svg.png' }
    ]

    return (
        <div>
            <div>
                <Header isDarkMode={storedTheme} onMenuClick={handleMenuClick} />
                <div className=" bg-white dark:bg-customBlack flex lg:flex-row max-lg:flex-col max-lg:pt-14">
                    <div className="flex-1 flex flex-col justify-center">
                        <p style={{ fontWeight: 800 }} className="text-customBlack dark:text-white text-6xl max-lg:text-5xl px-6 max-lg:text-center">Mugup</p>
                        <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-5xl max-lg:text-3xl px-6 max-lg:text-center mt-2">Ai Course Generator</p>
                        <p style={{ fontWeight: 500, }} className="text-customBlack dark:text-white max-lg:w-5/6 mt-4 max-xl:text-base text-lg max-lg:text-sm px-6 max-lg:text-center max-lg:self-center">
                            Ai Text To Course Generator transforms text into courses, making learning easy.
                            It features an AI teacher chat, short notes, and audio courses for comprehensive learning.
                        </p>
                        <Button onClick={() => navigate('/comingsoon')} style={{ fontWeight: 600, borderRadius: 5 }} className="mt-8 mx-6 w-2/4 max-lg:self-center bg-customBlack dark:bg-white text-white dark:text-customBlack enabled:hover:bg-customBlack enabled:dark:hover:bg-white focus:ring-transparent dark:focus:ring-transparent">Get Started Free</Button>
                    </div>
                    <div className="flex-1 flex justify-center items-center">
                        <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={LandingImage} />
                    </div>
                </div>
                <div className="justify-center bg-white dark:bg-customBlack flex flex-col">
                    <p style={{ fontWeight: 600, }} className="text-customBlack dark:text-white max-lg:w-5/6 mt-4 max-xl:text-base text-lg max-lg:text-sm px-6 text-center self-center py-8">
                        Generate Theory & Video Course Or Theory & Image Course In One Click
                        <br />
                        In More Than 23+ Languages
                    </p>
                    <img className="w-4/6 max-md:w-11/12 h-full object-contain self-center" src={MockUp} />
                </div>
                <div className="max-lg:hidden bg-customWhite dark:bg-black">
                    <div className=" bg-customWhite dark:bg-black flex lg:flex-row max-lg:flex-col max-lg:pt-8">
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={One} />
                        </div>
                        <div className="flex-1 flex flex-col justify-center">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl max-lg:text-center">Generate Ai Course</p>
                            <p style={{ fontWeight: 500 }} className=" text-customBlack dark:text-white mt-4 text-base max-lg:text-sm lg:pr-24 max-lg:text-center">
                                Need a head start? AI instantly creates your course, providing customized
                                content tailored to your needs. Jumpstart your learning journey with our AI-driven
                                course creation. Save time and get expert-level guidance effortlessly.
                            </p>
                        </div>
                    </div>
                    <div className=" bg-customWhite dark:bg-black flex lg:flex-row max-lg:flex-col max-lg:pt-8">
                        <div className="flex-1 flex flex-col justify-center">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl lg:pl-24 max-lg:text-center">Generate Ai Audio Course</p>
                            <p style={{ fontWeight: 500 }} className="text-customBlack dark:text-white mt-4 text-base max-lg:text-sm lg:pl-24 max-lg:text-center">
                                Busy but eager to learn? Generate AI audio courses seamlessly and fit learning into
                                your schedule. Enjoy convenient, hands-free education anywhere, anytime.
                            </p>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={Two} />
                        </div>
                    </div>
                    <div className=" bg-customWhite dark:bg-black flex lg:flex-row max-lg:flex-col max-lg:pt-8">
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={Three} />
                        </div>
                        <div className="flex-1 flex flex-col justify-center">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl max-lg:text-center">Generate Ai Short Notes</p>
                            <p style={{ fontWeight: 500 }} className="text-customBlack dark:text-white mt-4 text-base max-lg:text-sm  lg:pr-24 max-lg:text-center">
                                Short on time? Generate short notes for quick revision with our AI tool. Get
                                concise, clear summaries to help you review efficiently. Maximize your study
                                time and boost your retention effortlessly.
                            </p>
                        </div>
                    </div>
                    <div className=" bg-customWhite dark:bg-black flex lg:flex-row max-lg:flex-col max-lg:pt-8">
                        <div className="flex-1 flex flex-col justify-center">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl  lg:pl-24 max-lg:text-center">Chat With Ai Teacher</p>
                            <p style={{ fontWeight: 500 }} className="text-customBlack dark:text-white mt-4 text-base max-lg:text-sm  lg:pl-24 max-lg:text-center">
                                No tutors available? Chat with our AI teacher for timely assistance. Get instant
                                answers and support anytime you need. Enhance your learning experience with
                                personalized AI guidance.
                            </p>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={Four} />
                        </div>
                    </div>
                </div>
                <div className="lg:hidden ">
                    <div className=" bg-customWhite dark:bg-black flex lg:flex-row max-lg:flex-col max-lg:pt-8">
                        <div className="flex-1 flex flex-col justify-center">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl max-lg:text-center">Generate Ai Course</p>
                            <p style={{ fontWeight: 500 }} className="max-lg:w-5/6  max-lg:self-center text-customBlack dark:text-white mt-4 text-base max-lg:text-sm  lg:pr-24 max-lg:text-center">
                                Need a head start? AI instantly creates your course, providing customized
                                content tailored to your needs. Jumpstart your learning journey with our AI-driven
                                course creation. Save time and get expert-level guidance effortlessly.
                            </p>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={One} />
                        </div>
                    </div>
                    <div className=" bg-customWhite dark:bg-black flex lg:flex-row max-lg:flex-col max-lg:pt-8">
                        <div className="flex-1 flex flex-col justify-center">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl  max-lg:text-center">Generate Ai Audio Course</p>
                            <p style={{ fontWeight: 500 }} className="max-lg:w-5/6  max-lg:self-center text-customBlack dark:text-white mt-4 text-base max-lg:text-sm max-lg:text-center">
                                Busy but eager to learn? Generate AI audio courses seamlessly and fit learning into
                                your schedule. Enjoy convenient, hands-free education anywhere, anytime.
                            </p>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={Two} />
                        </div>
                    </div>
                    <div className=" bg-customWhite dark:bg-black flex lg:flex-row max-lg:flex-col max-lg:pt-8">
                        <div className="flex-1 flex flex-col justify-center">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl px-4 max-lg:text-center">Generate Ai Short Notes</p>
                            <p style={{ fontWeight: 500 }} className="max-lg:w-5/6  max-lg:self-center text-customBlack dark:text-white mt-4 text-base max-lg:text-sm px-4 max-lg:text-center">
                                Short on time? Generate short notes for quick revision with our AI tool. Get
                                concise, clear summaries to help you review efficiently. Maximize your study
                                time and boost your retention effortlessly.
                            </p>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={Three} />
                        </div>
                    </div>
                    <div className=" bg-customWhite dark:bg-black flex lg:flex-row max-lg:flex-col max-lg:pt-8">
                        <div className="flex-1 flex flex-col justify-center">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl px-4 max-lg:text-center">Chat With Ai Teacher</p>
                            <p style={{ fontWeight: 500 }} className="max-lg:w-5/6  max-lg:self-center text-customBlack dark:text-white mt-4 text-base max-lg:text-sm px-4 max-lg:text-center">
                                No tutors available? Chat with our AI teacher for timely assistance. Get instant
                                answers and support anytime you need. Enhance your learning experience with
                                personalized AI guidance.
                            </p>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={Four} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center bg-white dark:bg-customBlack">
                    <p style={{ fontWeight: 800 }} className="text-customBlack dark:text-white mt-16 text-4xl max-lg:text-3xl text-center">How It Works</p>
                    <div className="mt-16 flex flex-row max-lg:flex-col items-center justify-center lg:w-2/4 max-lg:w-5/6">
                        <div className="h-32 max-lg:h-24 items-center content-center">
                            <KeyboardIcon isDark={storedTheme} />
                        </div>
                        <div className="flex flex-col justify-center max-lg:mt-4 lg:ml-12">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl max-lg:text-center">Enter Title</p>
                            <p style={{ fontWeight: 500 }} className="text-customBlack dark:text-white text-base mt-4 max-lg:text-sm max-lg:text-center">
                                Enter any course title you have in mind, along with subtopics, the type of course you
                                want, and the language in which you want to generate the course.
                            </p>
                        </div>
                    </div>
                    <div className="mt-12 flex flex-row max-lg:flex-col items-center justify-center lg:w-2/4 max-lg:w-5/6">
                        <div className="h-32 max-lg:h-24 items-center content-center">
                            <GenerateIcon isDark={storedTheme} />
                        </div>
                        <div className="flex flex-col justify-center max-lg:mt-4  lg:ml-12">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl max-lg:text-center">Generate Course</p>
                            <p style={{ fontWeight: 500 }} className="text-customBlack dark:text-white text-base mt-4 max-lg:text-sm max-lg:text-center">
                                A complete roadmap of the course will be generated based on your input, which
                                will then be converted into a comprehensive course covering all the subtopics
                                needed to complete your course.
                            </p>
                        </div>
                    </div>
                    <div className="mt-12 flex flex-row max-lg:flex-col items-center justify-center lg:w-2/4 max-lg:w-5/6">
                        <div className="h-32 max-lg:h-24 items-center content-center">
                            <MoreIcon isDark={storedTheme} />
                        </div>
                        <div className="flex flex-col justify-center max-lg:mt-4  lg:ml-12">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl max-lg:text-center">Much More</p>
                            <p style={{ fontWeight: 500 }} className="text-customBlack dark:text-white text-base mt-4 max-lg:text-sm max-lg:text-center">
                                You can convert your AI course to an audio course and listen to it anytime, anywhere,
                                and receive a completion certificate. If you have any doubts, you can ask the AI teacher
                                to clarify them. You can also generate short notes for your course, and there is much more.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="h-full flex flex-col items-center bg-white dark:bg-customBlack">
                    <p style={{ fontWeight: 800 }} className="text-customBlack dark:text-white max-lg:pt-14 pt-16 text-4xl max-lg:text-3xl text-center">Why Mugup ?</p>
                    <Carousel pauseOnHover={true} indicators={false}>
                        {reviews.map((rev, index) => (
                            <Ratings key={index} photoURL={rev.photo} review={rev.review} from={rev.from} profession={rev.university} />
                        ))
                        }
                    </Carousel>
                </div>
                <div className="h-full flex flex-col items-center bg-white dark:bg-customBlack">
                    <p style={{ fontWeight: 800 }} className="text-customBlack dark:text-white max-lg:pt-4 pt-5 text-4xl max-lg:text-3xl text-center">Download App</p>
                    <div className="flex md:flex-row max-lg:flex-col py-12">
                        <div className=" flex-1 flex flex-col justify-center items-center max-xl:mb-4">
                            <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl text-center">Discover the Mobile App<br />Learn Anywhere, Anytime</p>
                            <p style={{ fontWeight: 500 }} className="max-lg:self-center text-customBlack dark:text-white mt-4 text-base max-lg:text-sm text-center mx-4">
                                Transform text into engaging courses with our mobile app. Experience interactive AI teacher chat, concise notes, and enriching audio courses. Elevate your learning experience. Download now.
                            </p>
                            <a href={playUrl} className="w-3/12 mt-4  h-3/12">
                                <img className="object-contain" src={PlayStore} alt="Play Store" />
                            </a>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 h-full object-contain" src={Phone} alt="Phone" />
                        </div>
                    </div>
                </div>
                <Footers isDarkMode={storedTheme} />
            </div>
            <LandDrawer isDarkMode={storedTheme} isOpen={isOpen} onClose={handleClose} />
        </div>
    );
};

export default Landing; 

import { useState } from "react";
import Footers from "../components/footer";
import Header from "../components/header";
import LandDrawer from "../components/landDrawer";
import Nothing from "../res/nothing.png";

const Error = () => {

    const storedTheme = sessionStorage.getItem('darkMode');
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);

    const handleMenuClick = () => {
        setIsOpen(true);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header isDarkMode={storedTheme} onMenuClick={handleMenuClick} />
            <div style={{ flex: 1, alignContent: 'center' }} className="py-8 dark:bg-customBlack">

                <div className="flex justify-center flex-col pb-8">
                    <img className="lg:w-2/6 max-md:w-3/5 max-lg:w-2/5 h-full object-contain self-center" src={Nothing} alt="Phone" />
                    <p style={{ fontWeight: 800 }} className="text-customBlack dark:text-white text-3xl max-lg:text-2xl mt-4 text-center">Page Not Found</p>
                </div>

            </div>
            <Footers isDarkMode={storedTheme} />
            <LandDrawer isDarkMode={storedTheme} isOpen={isOpen} onClose={handleClose} />
        </div>
    );
};

export default Error;

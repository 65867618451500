import { Footer } from "flowbite-react";
import LogoComponent from "./logoComponent";
import { useNavigate } from "react-router-dom";

const Footers = ({ isDarkMode }) => {
    const navigate = useNavigate();
    return (
        <Footer container className="rounded-none dark:bg-customBlack">
            <LogoComponent isDarkMode={isDarkMode} />
            <Footer.LinkGroup className="text-customBlack dark:text-white font-semibold max-md:mt-4" >
                <Footer.Link className="dark:hover:decoration-transparent hover:decoration-transparent" onClick={() => navigate('/about')}>About</Footer.Link>
                <Footer.Link className="dark:hover:decoration-transparent hover:decoration-transparent" onClick={() => navigate('/privacy')}>Privacy Policy</Footer.Link>
                <Footer.Link className="dark:hover:decoration-transparent hover:decoration-transparent" onClick={() => navigate('/terms')}>Terms</Footer.Link>
                <Footer.Link className="dark:hover:decoration-transparent hover:decoration-transparent" onClick={() => navigate('/contact')}>Contact Us</Footer.Link>
            </Footer.LinkGroup>
            <Footer.Copyright className="text-customBlack dark:text-white font-bold max-md:mt-4" by="Spacester" year={2024} />
        </Footer>
    );
};

export default Footers;
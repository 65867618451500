import { Button, Drawer, Sidebar } from "flowbite-react";
import LogoComponent from "./logoComponent";
import { useNavigate } from "react-router-dom";

const LandDrawer = ({ isDarkMode, isOpen, onClose }) => {
    const navigate = useNavigate();
    return (
        <Drawer open={isOpen} onClose={onClose} className="bg-white dark:bg-customBlack">
            <LogoComponent isDarkMode={isDarkMode} />
            <Drawer.Items>
                <Sidebar
                    aria-label="Sidebar with multi-level dropdown example"
                    className="[&>div]:bg-transparent [&>div]:p-0"
                >
                    <div className="flex h-full flex-col justify-between py-4 bg-white dark:bg-customBlack">
                        <Sidebar.Items >
                            <Sidebar.ItemGroup style={{ marginLeft: 6 }}>
                                <p style={{  fontWeight: 600, fontSize: 14 }} className="mt-4 text-customBlack dark:text-white" onClick={() => navigate('/')}>Home</p>
                                <p style={{  fontWeight: 600, fontSize: 14 }} className="pt-4 text-customBlack dark:text-white" onClick={() => navigate('/comingsoon')}>Pricing</p>
                                <p style={{  fontWeight: 600, fontSize: 14 }} className="pt-4 text-customBlack dark:text-white"  onClick={() => navigate('/comingsoon')}>Features</p>
                                <p style={{  fontWeight: 600, fontSize: 14 }} className="pt-4 pb-4 text-customBlack dark:text-white"  onClick={() => navigate('/comingsoon')}>Sign In</p>
                                <Button style={{ fontWeight: 600, fontSize: 14, borderRadius: 5 }} className="w-full bg-customBlack dark:bg-white text-white dark:text-customBlack enabled:hover:bg-customBlack enabled:dark:hover:bg-white focus:ring-transparent dark:focus:ring-transparent" onClick={() => navigate('/comingsoon')}>Sign Up</Button>
                            </Sidebar.ItemGroup>
                        </Sidebar.Items>
                    </div>
                </Sidebar>
            </Drawer.Items>
        </Drawer>
    );

};

export default LandDrawer;

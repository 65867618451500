import { useState } from "react";
import Header from "../components/header";
import Footers from "../components/footer";
import LandDrawer from "../components/landDrawer";
import { company, name } from "../constants";
import AboutImage from '../res/about.png';
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const About = () => {

    const storedTheme = sessionStorage.getItem('darkMode');
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);
    const navigate = useNavigate();
    const handleMenuClick = () => {
        setIsOpen(true);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header isDarkMode={storedTheme} onMenuClick={handleMenuClick} />
            <div style={{ flex: 1 }} className=" dark:bg-customBlack">
                <div className='flex py-10 px-8 flex-col'>
                    <div className=" bg-white dark:bg-customBlack flex lg:flex-row max-lg:flex-col">
                        <div className="flex-1 flex flex-col justify-center">
                            <p className='lg:text-start max-lg:text-center font-bold text-3xl text-customBlack dark:text-white'>About Us</p>
                            <p className="text-start text-customBlack mt-4 font-medium text-sm max-md:text-xs dark:text-white max-lg:text-center">
                                Welcome to {name}, the cutting-edge AI Course generator brought to you by {company}!
                            </p>
                            <p style={{ fontWeight: 700 }} className="text-customBlack mt-8 dark:text-white text-2xl max-lg:text-center">About Mugup</p>
                            <p className="text-start text-customBlack mt-4 font-medium text-sm max-md:text-xs dark:text-white max-lg:text-center">
                                At {company}, our dedication to education and our unwavering faith in Artificial Intelligence's boundless capabilities propel us forward. We're steadfast in our commitment to leveraging AI's transformative power to redefine the educational landscape.
                                <br />
                                <br />
                                Meet {name}, our revolutionary SaaS solution meticulously engineered to reshape course creation. By seamlessly integrating cutting-edge AI technology, we've made the process smarter and more efficient than ever before.
                                <br />
                                <br />
                                {name} empowers educators and content creators to explore unprecedented opportunities. Our AI Teacher Chat offers real-time, personalized support, whether it's clarifying doubts, providing feedback, or offering additional resources. Furthermore, Short Notes distill complex concepts into digestible snippets, while Audio Courses cater to diverse learning styles, enriching comprehension and retention.
                                <br />
                                <br />
                                At {company}, we're driven by a vision of democratized education and lifelong learning. Join us in embracing the future of education with {name}, where innovation merges seamlessly with learning to unlock endless possibilities.
                            </p>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <img className="w-4/5 max-lg:w-2/4 max-md:w-4/5 h-full object-contain" src={AboutImage} />
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-center mt-4">
                        <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-2xl max-lg:text-center mt-2">Our Mission</p>
                        <p className="text-start text-customBlack mt-4 font-medium text-sm max-md:text-xs dark:text-white max-lg:text-center">
                            At {company}, our mission revolves around empowering educators, professionals, and organizations to effortlessly craft exceptional learning experiences. This mission is deeply ingrained in our ethos, as we believe that education should be accessible, engaging, and transformative for all.
                            <br />
                            <br />
                            {name} serves as the embodiment of our dedication to this cause, as we harness the power of AI technology to streamline the course development process and unlock new frontiers of educational excellence. From educators shaping young minds to professionals enhancing their skills, {name} is designed to facilitate seamless learning experiences for all.
                            <br />
                            <br />
                            Since our inception, {company} has been driven by a fervent passion for innovation at the intersection of education and technology. Our journey has been guided by a shared vision of revolutionizing online learning, and our team of experts has dedicated years to crafting {name} as a testament to this commitment.
                            <br />
                            <br />
                            At {company}, we uphold an unwavering commitment to quality. {name} stands as a testament to this commitment, representing the culmination of meticulous development efforts. By integrating the latest advancements in AI technology, {name} surpasses expectations, providing users with a tool that not only simplifies course creation but also sets new standards for excellence in online education.
                        </p>
                    </div>
                    <div className="flex-1 flex flex-col justify-center my-8">
                        <p style={{ fontWeight: 700 }} className="text-customBlack dark:text-white text-2xl max-lg:text-center mt-2">Join Us on the Learning Journey</p>
                        <p className="text-start text-customBlack mt-4 font-medium text-sm max-md:text-xs dark:text-white max-lg:text-center">
                            Embark on a journey of innovation and educational excellence with {name}. Whether you're an educator, a professional, or an organization looking to elevate your learning programs, {company} is here to support you every step of the way.
                        </p>
                        <Button onClick={()=> navigate('/contact')} style={{ fontWeight: 600, borderRadius: 5 }} className="mt-4 w-3/12 max-md:w-3/5 max-lg:self-center bg-customBlack dark:bg-white text-white dark:text-customBlack enabled:hover:bg-customBlack enabled:dark:hover:bg-white focus:ring-transparent dark:focus:ring-transparent">Contact Us</Button>
                    </div>

                </div>
            </div>
            <Footers isDarkMode={storedTheme} />
            <LandDrawer isDarkMode={storedTheme} isOpen={isOpen} onClose={handleClose} />
        </div>
    );
};

export default About;

import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Landing from './pages/landing';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Billing from './pages/billing';
import Refund from './pages/refund';
import Cancellation from './pages/cancellation';
import DeletePolicy from './pages/deletePolicy';
import Soon from './pages/soon';
import Error from './pages/error';
import Contact from './pages/contact';
import About from './pages/about';
import SignIn from './pages/signin';

function App() {
  return (
    <Router>
      <div>
        <ToastContainer
          limit={3}
          progressClassName={sessionStorage.getItem('darkMode') === 'true' ? "toastProgressDark" : "toastProgress"}
          bodyClassName={sessionStorage.getItem('darkMode') === 'true' ? "toastBodyDark" : "toastBody"}
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={sessionStorage.getItem('darkMode') === 'true' ? 'dark' : 'light'}
        />
        <Routes>
          <Route path="/" exact element={<Landing />} />
          <Route path="/signin" exact element={<SignIn />} />
          <Route path="/terms" exact element={<Terms />} />
          <Route path="/privacy" exact element={<Privacy />} />
          <Route path="/billing" exact element={<Billing />} />
          <Route path="/refund" exact element={<Refund />} />
          <Route path="/cancellation" exact element={<Cancellation />} />
          <Route path="/deletepolicy" exact element={<DeletePolicy />} />
          <Route path="/comingsoon" exact element={<Soon />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/about" exact element={<About />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

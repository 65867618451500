import { useEffect, useState } from "react";
import Header from "../components/header";
import Footers from "../components/footer";
import LandDrawer from "../components/landDrawer";
import { serverURL } from "../constants";
import axios from "axios";
import { Spinner } from "flowbite-react";

const Refund = () => {

    const storedTheme = sessionStorage.getItem('darkMode');
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);

    const handleMenuClick = () => {
        setIsOpen(true);
    };

    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function dashboardData() {
            const postURL = serverURL + `/api/policies`;
            const response = await axios.get(postURL);
            setData(response.data[0].refund)
            setLoading(false);
        }
        dashboardData();
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header isDarkMode={storedTheme} onMenuClick={handleMenuClick} />
            <div style={{ flex: 1, alignContent: 'center' }} className=" dark:bg-customBlack">
                {loading ?
                    <div className="flex justify-center">
                        <Spinner size="lg" className="text-customWhite fill-customBlack dark:fill-customBlackTwo dark:text-white" />
                    </div>
                    :
                    <div className='flex py-10 px-8 flex-col'>
                        <p className='lg:text-start max-lg:text-center font-bold text-3xl text-customBlack dark:text-white'>Refund Policy</p>
                        <div className='text-customBlack mt-4 dark:text-white font-medium text-sm' dangerouslySetInnerHTML={{ __html: data }} />
                    </div>
                }
            </div>
            <Footers isDarkMode={storedTheme} />
            <LandDrawer isDarkMode={storedTheme} isOpen={isOpen} onClose={handleClose} />
        </div>
    );
};

export default Refund;

const MoreIcon = ({ isDark }) => {

    return (
        <svg width="69" height="69" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M54.888 48.3953C54.2567 51.0066 51.2738 52.852 45.308 56.5428C39.5405 60.1103 36.6571 61.8945 34.3331 61.1774C33.3723 60.8807 32.4969 60.3178 31.791 59.542C30.0834 57.6662 30.0834 54.0274 30.0834 46.7503C30.0834 39.4732 30.0834 35.8344 31.791 33.9584C32.4969 33.1829 33.3723 32.6198 34.3331 32.3234C36.6571 31.6063 39.5405 33.3902 45.308 36.9578C51.2738 40.6487 54.2567 42.4941 54.888 45.1053C55.1484 46.1832 55.1484 47.3174 54.888 48.3953Z" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M82.1571 40.5003C82.1667 42.4591 82.1667 44.5391 82.1667 46.7503C82.1667 65.4099 82.1667 74.7399 76.37 80.537C70.5729 86.3337 61.2429 86.3337 42.5833 86.3337C23.9236 86.3337 14.5937 86.3337 8.79683 80.537C3 74.7399 3 65.4099 3 46.7503C3 28.0906 3 18.7607 8.79683 12.9638C14.5937 7.16699 23.9236 7.16699 42.5833 7.16699C44.7946 7.16699 46.8746 7.16699 48.8333 7.17662" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" />
            <path d="M71.75 3L72.8246 5.90429C74.2338 9.71258 74.9384 11.6167 76.3275 13.0058C77.7167 14.3949 79.6209 15.0995 83.4292 16.5087L86.3334 17.5833L83.4292 18.658C79.6209 20.0672 77.7167 20.7718 76.3275 22.1609C74.9384 23.5499 74.2338 25.4541 72.8246 29.2624L71.75 32.1667L70.6754 29.2624C69.2663 25.4541 68.5617 23.5499 67.1725 22.1609C65.7834 20.7718 63.8792 20.0672 60.0709 18.658L57.1667 17.5833L60.0709 16.5087C63.8792 15.0995 65.7834 14.3949 67.1725 13.0058C68.5617 11.6167 69.2663 9.71258 70.6754 5.90429L71.75 3Z" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default MoreIcon; 
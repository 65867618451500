import { Avatar, Blockquote, Rating } from "flowbite-react";

const Ratings = ({ review, photoURL, from, profession }) => {
    return (
        <div className='dark:bg-customBlack'>
            <figure className="max-w-screen-md mx-auto py-16 flex flex-col items-center dark:bg-customBlack">
                <div className="mb-4 flex items-center">
                    <Rating size="md">
                        <Rating.Star color='black' className='fill-customBlack dark:fill-white' />
                        <Rating.Star color='black' className='fill-customBlack dark:fill-white' />
                        <Rating.Star color='black' className='fill-customBlack dark:fill-white' />
                        <Rating.Star color='black' className='fill-customBlack dark:fill-white' />
                        <Rating.Star color='black' className='fill-customBlack dark:fill-white' />
                    </Rating>
                </div>
                <Blockquote>
                    <p className="mx-4 text-base max-lg:text-sm max-lg:px-2 font-semibold text-customBlack dark:text-white text-center">
                        {review}
                    </p>
                </Blockquote>
                <figcaption className="mt-6 flex items-center space-x-3">
                    <Avatar rounded size="xs" img={photoURL} alt="profile picture" />
                    <div className="flex items-center divide-x-2 divide-customBlack dark:divide-white">
                        <cite className="pr-3 text-sm max-lg:text-xs font-semibold text-customBlack dark:text-white">{from}</cite>
                        <cite className="pl-3 text-sm max-lg:text-xs font-medium text-customBlack dark:text-white">{profession}</cite>
                    </div>
                </figcaption>
            </figure>
        </div>
    );
};

export default Ratings;

//BRAND
export const name = 'Mugup';
export const company = 'Spacester';
export const websiteURL = 'https://mugupai.com';
export const serverURL = 'https://mugup-4dd6e.uc.r.appspot.com';
export const playUrl = 'https://play.google.com/store/apps/details?id=com.spacester.Mugup';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/mugup-4dd6e.appspot.com/o/mugup_logo.png?alt=media&token=d7bcc5f2-6b8d-43d4-8d9f-f5bbea3beb08';

//PRICING COST INR
export const monthCostINR = 99;
export const yearCostINR = 499;

//PRICING COST USD
export const monthCostUSD = 9;
export const yearCostUSD = 49;

//PAYPAL PLAN ID
export const paypalPlanIdMonth = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdYear = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY PLAN ID
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIMonth = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdYear = "plan_NMRc9HARBQRLWA";
const GenerateIcon = ({ isDark }) => {

    return (
        <svg width="61" height="69" viewBox="0 0 81 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 40.5C3 24.8761 3 17.0642 6.97879 11.5878C8.26379 9.81917 9.81917 8.26379 11.5878 6.97879C17.0642 3 24.8761 3 40.5 3C56.1237 3 63.9358 3 69.4121 6.97879C71.1808 8.26379 72.7362 9.81917 74.0212 11.5878C78 17.0642 78 24.8761 78 40.5V48.8333C78 64.4571 78 72.2692 74.0212 77.7454C72.7362 79.5142 71.1808 81.0696 69.4121 82.3546C63.9358 86.3333 56.1237 86.3333 40.5 86.3333C24.8761 86.3333 17.0642 86.3333 11.5878 82.3546C9.81917 81.0696 8.26379 79.5142 6.97879 77.7454C3 72.2692 3 64.4571 3 48.8333V40.5Z" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M53 34.25H19.6667M32.1667 55.0833H19.6667" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default GenerateIcon; 
import { useState } from "react";
import Footers from "../components/footer";
import Header from "../components/header";
import LandDrawer from "../components/landDrawer";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { toast } from "react-toastify";
import { serverURL } from "../constants";
import axios from "axios";

const Contact = () => {

    const storedTheme = sessionStorage.getItem('darkMode');
    const [isOpen, setIsOpen] = useState(false);
    const [process, setProcess] = useState(false);
    const handleClose = () => setIsOpen(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [msg, setMsg] = useState('');

    const showToast = async (msg) => {
        setProcess(false);
        setFname('');
        setLname('');
        setEmail('');
        setPhone('');
        setMsg('');
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    const handleMenuClick = () => {
        setIsOpen(true);
    };

    async function submit() {
        if (!email || !phone || !fname || !lname || !msg) {
            showToast('Please fill in all required fields');
            return;
        }
        const postURL = serverURL + '/api/contact';
        try {
            setProcess(true);
            const response = await axios.post(postURL, { fname, lname, email, phone, msg });
            if (response.data.success) {
                showToast(response.data.message);
            } else {
                showToast(response.data.message);
            }
        } catch (error) {
            showToast('Internal Server Error');
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header isDarkMode={storedTheme} onMenuClick={handleMenuClick} />
            <div style={{ flex: 1 }} className="py-8 dark:bg-customBlack">

                <div className="flex items-center flex-col max-w-sm m-auto">
                    <p className=' font-bold text-3xl text-customBlack dark:text-white'>Contact Us</p>
                    <p className=' font-medium text-sm mt-4 max-md:text-xs text-customBlack dark:text-white text-center'>Kindly complete the form and share your message.<br />Expect a swift response from us shortly.</p>

                    <div className="md:flex-row max-md:flex-col flex mt-8 w-full">
                        <div className="flex-col flex-1  md:mr-2">
                            <p className=' font-semibold text-sm text-customBlack dark:text-white mb-2'>First Name</p>
                            <input onChange={(e) => setFname(e.target.value)} type="text" className="text-customBlack w-full dark:text-white  bg-customWhite dark:bg-customBlackTwo border-none h-14 outline-none focus:ring-0 font-medium" style={{ borderRadius: 5 }} />
                        </div>
                        <div className="flex-col flex-1 max-md:mt-4 md:ml-2">
                            <p className=' font-semibold text-sm text-customBlack dark:text-white mb-2'>Last Name</p>
                            <input onChange={(e) => setLname(e.target.value)} type="text" className="text-customBlack w-full dark:text-white  bg-customWhite dark:bg-customBlackTwo border-none h-14 outline-none focus:ring-0 font-medium" style={{ borderRadius: 5 }} />
                        </div>
                    </div>

                    <div className="md:flex-row max-md:flex-col w-full flex mt-4 ">
                        <div className="flex-col flex-1  md:mr-2">
                            <p className=' font-semibold text-sm text-customBlack dark:text-white mb-2'>Email</p>
                            <input onChange={(e) => setEmail(e.target.value)} type="email" className="text-customBlack w-full dark:text-white  bg-customWhite dark:bg-customBlackTwo border-none h-14 outline-none focus:ring-0 font-medium" style={{ borderRadius: 5 }} />
                        </div>
                        <div className="flex-col flex-1 max-md:mt-4 md:ml-2">
                            <p className=' font-semibold text-sm text-customBlack dark:text-white mb-2'>Phone</p>
                            <input onChange={(e) => setPhone(e.target.value)} type="tel" className="text-customBlack w-full dark:text-white  bg-customWhite dark:bg-customBlackTwo border-none h-14 outline-none focus:ring-0 font-medium" style={{ borderRadius: 5 }} />
                        </div>
                    </div>

                    <div className="flex-col flex-1 mt-4 w-full">
                        <p className='font-semibold text-sm text-customBlack dark:text-white mb-2'>Message</p>
                        <textarea onChange={(e) => setMsg(e.target.value)} type="tel" className="w-full text-customBlack dark:text-white bg-customWhite dark:bg-customBlackTwo border-none h-14 outline-none focus:ring-0 font-medium" style={{ borderRadius: 5 }} />
                    </div>

                    <Button onClick={submit} style={{ fontWeight: 600, borderRadius: 5 }} isProcessing={process} processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin text-white dark:text-customBlack" />} className="my-6 w-full max-lg:self-center bg-customBlack dark:bg-white text-white dark:text-customBlack enabled:hover:bg-customBlack enabled:dark:hover:bg-white focus:ring-transparent dark:focus:ring-transparent">Submit</Button>

                    <p className="font-semibold self-start">Email:</p>
                    <p className="font-medium self-start">support@mugupai.com</p>
                    <p className="font-semibold self-start">Phone:</p>
                    <p className="font-medium self-start">+91 9372592711</p>
                    <p className="font-semibold self-start">Address:</p>
                    <p className="font-medium self-start">Aastha, Plot 98, Sector 22, Kamothe, Navi Mumabi, Postal Code 410209</p>

                </div>

            </div>
            <Footers isDarkMode={storedTheme} />
            <LandDrawer isDarkMode={storedTheme} isOpen={isOpen} onClose={handleClose} />
        </div>
    );
};

export default Contact;

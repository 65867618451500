import React from 'react';
import Logo from '../res/logo.svg';
import DarkLogo from '../res/darkLogo.svg';
import { websiteURL } from '../constants';

const LogoComponent = ({ isDarkMode }) => {

    function redirectHome() {
        window.location.href = websiteURL;
    }

    return <img alt='logo' src={isDarkMode === "true" ? DarkLogo : Logo} className="h-7 max-md:m-1" onClick={redirectHome} />;
};

export default LogoComponent;

const KeyboardIcon = ({ isDark }) => {

    return (
        <svg width="69" height="69" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.0833 23.8335H34.25C20.5522 23.8335 13.7033 23.8335 9.09346 27.6167C8.24954 28.3092 7.47575 29.083 6.78317 29.927C3 34.5367 3 41.3856 3 55.0835C3 68.7814 3 75.6302 6.78317 80.2402C7.47575 81.0839 8.24954 81.8577 9.09346 82.5502C13.7033 86.3335 20.5522 86.3335 34.25 86.3335H55.0833C68.7813 86.3335 75.63 86.3335 80.24 82.5502C81.0838 81.8577 81.8575 81.0839 82.55 80.2402C86.3333 75.6302 86.3333 68.7814 86.3333 55.0835C86.3333 41.3856 86.3333 34.5367 82.55 29.927C81.8575 29.083 81.0838 28.3092 80.24 27.6167C75.63 23.8335 68.7813 23.8335 55.0833 23.8335Z" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" />
            <path d="M44.6667 23.8333V15.5C44.6667 13.1988 46.5321 11.3333 48.8334 11.3333C51.1346 11.3333 53 9.46783 53 7.16667V3" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.8334 44.667H28" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.5834 44.667H46.75" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M61.3333 44.667H65.5" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23.8334 65.5H65.5" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default KeyboardIcon; 
import { Button, Navbar } from "flowbite-react";
import { websiteURL } from "../constants";
import NavIcon from "../icons/navIcon";
import LogoComponent from "./logoComponent";
import { useNavigate } from "react-router-dom";
import DarkIcon from "../icons/darkIcon";

const Header = ({ isDarkMode, onMenuClick }) => {

    const navigate = useNavigate();

    return (
        <Navbar fluid className="bg-white dark:bg-customBlack pt-4">
            <Navbar.Brand href={websiteURL} className="px-4">
                <LogoComponent isDarkMode={isDarkMode} />
            </Navbar.Brand>
            <div className="flex md:order-2 px-4">
                <Button className="text-customBlack dark:text-white bg-white dark:bg-customBlack max-md:hidden enabled:hover:bg-white enabled:dark:hover:bg-customBlack focus:ring-transparent dark:focus:ring-transparent" style={{ fontWeight: 600, fontSize: 14 }} onClick={() => navigate('/comingsoon')}>Sign In</Button>
                <Button style={{ fontWeight: 600, fontSize: 14, borderRadius: 5 }} className="max-md:hidden bg-customBlack dark:bg-white text-white dark:text-customBlack enabled:hover:bg-customBlack enabled:dark:hover:bg-white focus:ring-transparent dark:focus:ring-transparent" onClick={() => navigate('/comingsoon')}>Sign Up</Button>
                <div onClick={onMenuClick} className="md:hidden">
                    <NavIcon isDark={isDarkMode} />
                </div>
            </div>
            <Navbar.Collapse>
                <Navbar.Link className="text-customBlack dark:text-white md:hover:text-customBlack md:hover:dark:text-white" style={{ fontWeight: 600, fontSize: 14 }} ></Navbar.Link>
                <Navbar.Link className="text-customBlack dark:text-white md:hover:text-customBlack md:hover:dark:text-white" style={{ fontWeight: 600, fontSize: 14 }} ></Navbar.Link>
                <Navbar.Link className="text-customBlack dark:text-white mt-1 md:hover:text-customBlack md:hover:dark:text-white" style={{ fontWeight: 600, fontSize: 14 }} onClick={() => navigate('/')}>Home</Navbar.Link>
                <Navbar.Link className="text-customBlack dark:text-white mt-1 md:hover:text-customBlack md:hover:dark:text-white" style={{ fontWeight: 600, fontSize: 14 }} onClick={() => navigate('/comingsoon')}>Pricing</Navbar.Link>
                <Navbar.Link className="text-customBlack dark:text-white mt-1 md:hover:text-customBlack md:hover:dark:text-white" style={{ fontWeight: 600, fontSize: 14 }} onClick={() => navigate('/comingsoon')}>Features</Navbar.Link>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;

const NavIcon = ({ isDark }) => {

    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 8H7" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 1H1" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 15H1" stroke={isDark === 'true' ? '#fff' : '#01020A'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default NavIcon; 
